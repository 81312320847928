var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-reject-deposit","cancel-variant":"outline-secondary","ok-title":_vm.$t('Submit'),"cancel-title":_vm.$t('Close'),"ok-variant":"danger","centered":"","title":_vm.$t('Reject deposit transaction'),"modal-class":"modal-danger","no-close-on-backdrop":true},on:{"ok":_vm.confirmRejectDeposit,"hidden":_vm.resetForm}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validationFormRejectDeposit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"transactionId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Transaction id'),"label-for":"transactionId"}},[_c('b-form-input',{attrs:{"id":"name","trim":"","disabled":""},model:{value:(_vm.deposit.reference),callback:function ($$v) {_vm.$set(_vm.deposit, "reference", $$v)},expression:"deposit.reference"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Username'),"label-for":"transactionId"}},[_c('b-form-input',{attrs:{"id":"name","trim":"","disabled":""},model:{value:(_vm.deposit.username),callback:function ($$v) {_vm.$set(_vm.deposit, "username", $$v)},expression:"deposit.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Currency'),"label-for":"transactionId"}},[_c('b-form-input',{attrs:{"id":"currency","trim":"","disabled":""},model:{value:(_vm.deposit.currency),callback:function ($$v) {_vm.$set(_vm.deposit, "currency", $$v)},expression:"deposit.currency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":((_vm.$t('Amount')) + " (Gross)"),"label-for":"transactionId"}},[_c('b-form-input',{attrs:{"id":"amount","trim":"","disabled":""},model:{value:(_vm.deposit.reviewAmount),callback:function ($$v) {_vm.$set(_vm.deposit, "reviewAmount", $$v)},expression:"deposit.reviewAmount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-for":"attachments"}},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t('Bills'))+" "),_c('span',{staticClass:"text-secondary"},[_vm._v("(Không > 10Mb)")])]),_c('validation-provider',{attrs:{"name":"attachments","rules":"size:10240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"multiple":"","input-id":"attachments","accept":"image/jpeg,image/png,image/gif,image/svg,image/jpg"},model:{value:(_vm.attachments),callback:function ($$v) {_vm.attachments=$$v},expression:"attachments"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.attachments.length != 0),expression:"attachments.length != 0"}],staticClass:"btn-sm btn-icon",attrs:{"variant":"outline-danger"},on:{"click":function($event){_vm.attachments = []}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"remark","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"remark"}},[_c('label',{attrs:{"for":"remark"}},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('Remark')))]),_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.remarkOptions,"reduce":function (val) { return val.label; },"clearable":false,"input-id":"remark"},model:{value:(_vm.deposit.remark),callback:function ($$v) {_vm.$set(_vm.deposit, "remark", $$v)},expression:"deposit.remark"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }