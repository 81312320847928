<template>
	<div>
		<b-modal
			id="modal-reject-deposit"
			cancel-variant="outline-secondary"
			:ok-title="$t('Submit')"
			:cancel-title="$t('Close')"
			ok-variant="danger"
			centered
			:title="$t('Reject deposit transaction')"
			modal-class="modal-danger"
			:no-close-on-backdrop="true"
			@ok="confirmRejectDeposit"
			@hidden="resetForm"
		>
			<!-- form -->
			<validation-observer
				#default="{ handleSubmit }"
				ref="refFormObserver"
			>
				<b-form
					@submit.prevent="handleSubmit(validationFormRejectDeposit)"
					@reset.prevent="resetForm"
				>
					<b-row>
						<!-- Transaction Id -->
						<b-col
							cols="12"
						>
							<validation-provider
								#default="{ errors }"
								name="transactionId"
								rules="required"
							>
								<b-form-group
									:label="$t('Transaction id')"
									label-for="transactionId"
								>
									<b-form-input
										id="name"
										v-model="deposit.reference"
										trim
										disabled
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</b-form-group>
							</validation-provider>
						</b-col>

						<!-- Username -->
						<b-col
							cols="12"
						>
							<validation-provider
								#default="{ errors }"
								name="username"
								rules="required"
							>
								<b-form-group
									:label="$t('Username')"
									label-for="transactionId"
								>
									<b-form-input
										id="name"
										v-model="deposit.username"
										trim
										disabled
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</b-form-group>
							</validation-provider>
						</b-col>

						<!-- Currency -->
						<b-col
							cols="12"
						>
							<validation-provider
								#default="{ errors }"
								name="currency"
								rules="required"
							>
								<b-form-group
									:label="$t('Currency')"
									label-for="transactionId"
								>
									<b-form-input
										id="currency"
										v-model="deposit.currency"
										trim
										disabled
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</b-form-group>
							</validation-provider>
						</b-col>

						<!-- Amount -->
						<b-col
							cols="12"
						>
							<validation-provider
								#default="{ errors }"
								name="amount"
								rules="required"
							>
								<b-form-group
									:label="`${$t('Amount')} (Gross)`"
									label-for="transactionId"
								>
									<b-form-input
										id="amount"
										v-model="deposit.reviewAmount"
										trim
										disabled
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</b-form-group>
							</validation-provider>
						</b-col>

						<!-- Bill -->
						<b-col
							cols="12"
						>
							<b-form-group
								label-for="attachments"
							>
								<label for="">{{ $t('Bills') }} <span class="text-secondary">(Không > 10Mb)</span></label>
								<validation-provider
									#default="{ errors }"
									name="attachments"
									rules="size:10240"
								>
									<b-form-file
										v-model="attachments"
										multiple
										input-id="attachments"
										accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
								
							</b-form-group>
							<b-button
								v-show="attachments.length != 0"
								variant="outline-danger"
								class="btn-sm btn-icon"
								@click="attachments = []"
							>
								<feather-icon icon="XIcon"/>
							</b-button>
						</b-col>

						<!-- Remark -->
						<b-col
							cols="12"
						>
							<validation-provider
								#default="{ errors }"
								name="remark"
								rules="required"
							>
								<b-form-group
									label-for="remark"
								>
									<label for="remark"><span class="text-danger">*</span> {{ $t('Remark') }}</label>
									<v-select
										v-model="deposit.remark"
										:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
										:options="remarkOptions"
										:reduce="val => val.label"
										:clearable="false"
										input-id="remark"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</b-form-group>
							</validation-provider>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BFormFile, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { numberFormat } from '@core/utils/filter'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import useUploadBill from '@/views/payments/upload-bill/useUploadBill'

export default {
	components: {
		BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BFormFile, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		BFormInvalidFeedback,
		ValidationProvider,
		ValidationObserver,
		vSelect,
		flatPickr,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		depositData: {
			type: [Object, null],
			required: false,
			default: null,
		},
	},
	data() {
		return {
			deposit: {
				id: null,
				reference: null,
				username: null,
				currency: null,
				amount: null,
				status: 3, // 3 = Reject
				remark: null,
			},
			attachments: []
		}
	},
	watch: {
		depositData: {
			deep: true,
			handler(newVal) {
				if (newVal) {
					this.deposit = {
						id: newVal.id,
						reference: newVal.ref,
						username: newVal.user ? newVal.user.username : 'n/a',
						currency: newVal.currency,
						amount: newVal.amount / 1000,
						reviewAmount: numberFormat(newVal.amount / 1000),
						status: 3, // 3 = Reject
					}
				}
			}
		}
	},
	methods: {
		validationFormRejectDeposit() {
			this.$refs.refFormObserver.validate().then(success => {
				if (success) {
					store.dispatch('payment-deposit/updateDepositStatus', this.deposit).then(response => {
							if (response.status === 200) {
								if (response.data.code == '00') {
									this.$emit('refetch-data')
									if (this.attachments.length > 0) {
										this.uploadBill({
											ref: this.deposit.reference,
											attachments: this.attachments
										})
									}
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message || 'success',
											icon: 'CheckIcon',
											variant: 'success',
										},
									})
									this.$nextTick(() => {
										setTimeout(() => {
											this.$bvModal.hide('modal-reject-deposit')
											this.resetForm()
										}, '500')
									})
								} else {
									this.$toast({
										component: ToastificationContent,
										props: {
											title: response.data.message,
											icon: 'AlertTriangleIcon',
											variant: 'danger',
										},
									})
								}
							}
						})
						.catch(error => {
							const { response } = error
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message || 'Error update deposits',
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						})
				}
			})
		},
		confirmRejectDeposit(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormRejectDeposit()
		},
		resetForm() {
			this.$emit('update:depositData', null)
			this.attachments = []
		},
	},
	setup() {
		const { uploadBill } = useUploadBill()
		const remarkOptions = [
			{ label: 'No fund', value: 1 },
			{ label: 'No fund - no feedback from CS/TSM', value: 2 },
			{ label: 'Advanced Credit', value: 3 },
			{ label: 'Bank Account Sharing with Other Member', value: 4 },
			{ label: 'Aldready Credited to Other Member', value: 5 },
			{ label: 'Using Third Party', value: 6 },
			{ label: 'Incorrect Name', value: 7 },
			{ label: 'Incomplete Name', value: 8 },
		]

		return {
			remarkOptions,
			uploadBill,
		}
	},
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
